@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Denotive Colors */
/* Interactive Colors */
.hero__container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #191b19;
}

a {
  text-decoration: none;
  color: #e06f2c;
}

.hero__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80%;
}

.hero__typewriting-text {
  display: flex;
  box-sizing: border-box;
  width: 8rem;
  border-right: 2px solid #e06f2c;
  text-align: center;
  color: #e06f2c;
  font-size: 2rem;
  font-family: "Source Code Pro";
  white-space: nowrap;
  overflow: hidden;
  animation: typewriter 2s steps(13) 1s 1 normal both, blinkTextCursor 500ms steps(13) infinite normal;
}

.hero__tagline {
  display: flex;
  text-align: center;
  font-size: 1.5rem;
  color: #f4f5f2;
  animation: 6s ease 0s normal forwards 1 fadein;
}

.icon-container {
  display: flex;
  animation: 6s ease 0s normal forwards 1 fadein;
}

.hero__icon {
  width: 4rem;
  height: 4rem;
  cursor: pointer;
}

.hero__enter-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 3rem;
  align-items: center;
  justify-content: center;
  animation: 6s ease 0s normal forwards 1 fadein;
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 8em;
  }
}
@keyframes blinkTextCursor {
  from {
    border-right-color: #e06f2c;
  }
  to {
    border-right-color: transparent;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*# sourceMappingURL=Landing.css.map */

/* Denotive Colors */
/* Interactive Colors */
.nav {
  display: flex;
  height: 4rem;
  align-items: center;
}

.nav-link {
  text-decoration: none;
  color: #191b19;
}

.nav-link--active {
  font-weight: bolder;
}

/*# sourceMappingURL=Nav.css.map */

/* Denotive Colors */
/* Interactive Colors */
.about__container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: #f4f5f2;
  align-items: center;
}

.about__header {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.about__title {
  box-sizing: border-box;
}

.about__intro {
  box-sizing: border-box;
  font-weight: bolder;
  text-align: center;
  padding: 4px 12px;
  font-size: 1.2rem;
}

.about__card {
  display: flex;
  box-sizing: border-box;
  width: 90%;
  max-width: 500px;
  min-width: 280px;
  margin-bottom: 2rem;
}

.about__right-now-card__content {
  box-sizing: border-box;
  background: #e06f2c;
}

.about__tech-card__content {
  box-sizing: border-box;
  background: #191b19;
  color: #f4f5f2;
}

.about__right-now {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 4px 12px;
  text-align: center;
}

.about__right-now-title {
  font-size: 2rem;
}

.about__right-now-text {
  font-weight: bolder;
}

.about__tech {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 4px 12px;
  text-align: center;
}

.about__icon-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.about__footer {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  text-align: center;
  padding: 4px 12px;
  align-items: center;
  justify-content: center;
  background: #191b19;
  color: #e06f2c;
}

/*# sourceMappingURL=About.css.map */

/* Denotive Colors */
/* Interactive Colors */
.work__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 100vh;
  background: #f4f5f2;
  color: #191b19;
}

.work__header {
  display: flex;
  box-sizing: border-box;
  margin-top: 4rem;
  width: 100%;
  align-content: center;
  justify-content: center;
}

.work__card-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.work__card {
  display: flex;
  flex-direction: column;
  min-width: 280px;
  max-width: 500px;
  margin: 1rem;
  box-shadow: 3px 5px 15px 7px rgba(0, 0, 0, 0.6);
  border-radius: 15px;
}

.work__card-image {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  min-height: 280px;
  background-image: url(/static/media/under-928246.a9985fb0.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 15px 15px 0;
}

.work__card-content {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #191b19;
  color: #f4f5f2;
  border-radius: 0 0 15px 15px;
}

.work__card-content h4,
h5 {
  box-sizing: border-box;
  margin: 0;
  color: #e06f2c;
}

/*# sourceMappingURL=Work.css.map */

/* Denotive Colors */
/* Interactive Colors */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.7);
}

*::-webkit-scrollbar-thumb {
  background: #e06f2c;
}

html {
  scroll-behavior: smooth;
}

.App {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100vh;
  min-width: 280px;
  font-family: "Quicksand", sans-serif;
}

.MuiList-root {
  background: #191b19;
  text-align: center;
  height: 100%;
}

.app-nav-item {
  margin: 1rem;
}

.app-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 280px;
  background: #f4f5f2;
}

.app-footer {
  display: flex;
  height: 2rem;
  min-width: 280px;
  background: #191b19;
  align-items: center;
  justify-content: center;
}

.app-footer p {
  box-sizing: border-box;
  margin: 0;
  color: #e06f2c;
}

/*# sourceMappingURL=App.css.map */

